import React from 'react';
import '../css/FAQ.css';

const Contacto = () => {
    return (
        <div className="faq">
            <div className="faq-overlay"></div>
            <div className="faq-content">
                <h1>Contacto</h1>
            </div>
        </div>
    );
}

export default Contacto;
