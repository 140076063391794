import React from 'react';
import '../css/GuiaEntrevista.css';

const GuiaEntrevista = () => {
    return (
        <div className="guia-entrevista">
            <div className="guia-entrevista-content">
                <h2>Gratis una Guía para la entrevista</h2>
                <p>
                    Nuestra Guía para la entrevista en la embajada incluye información útil y esencial para poder obtener la autorización B1 y la autorización
                    B2. La guía es gratuita, aquí encontrará consejos para su cita en la embajada y entrevistas simuladas.
                </p>
            </div>
        </div>
    );
}

export default GuiaEntrevista;
