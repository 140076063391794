import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import AnimatedButton from './animations/AnimatedButton';
import '../css/CitaEmbajada.css';

const CitaEmbajada = () => {
    return (
        <div className="cita-embajada">
            <div className="cita-header">
                <a href="#conoce-mas" className="conoce-mas">Conoce Más</a>
                <h1>Su cita en la embajada</h1>
            </div>
            <div className="cita-content">
                <AnimatedLeftBlock>
                    <div className="cita-image">
                        <img src="/Cita.png" alt="Cita en la embajada" />
                    </div>
                </AnimatedLeftBlock>
                <div className="cita-text">
                    <p>Una vez se ha realizado el pago de la tasa de la autorización, procederemos a concertar la cita en la embajada o consulado más cercano. Le enviaremos un correo electrónico con información detallada sobre su cita y una guía con consejos sobre cómo prepararse para la entrevista.</p>
                    <p>En US Visa B1B2 contamos con un equipo especializado en la tramitación de la autorización de turistas y la autorización de negocios. Le asistiremos en cada fase del proceso de solicitud de la autorización y le proporcionamos información útil para que obtenga la autorización con éxito.</p>
                    <AnimatedButton>
                        <WhatsAppButton 
                          text="Hola, quiero realizar una nueva solicitud para la autorización de entrada a EE.UU" 
                          buttonText={<button className="solicitud-button">Nueva Solicitud</button>}
                          className=""
                        />
                    </AnimatedButton>
                </div>
            </div>
        </div>
    );
}

export default CitaEmbajada;
