// Footer.jsx
import React from "react";
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <img src="/Logo.png" alt="Logo" className="footer-logo" />
          <p>
            USA Visa B1B2 es una empresa especializada en soporte a trámites de
            viaje. La tarifa de nuestros servicios incluye la asistencia experta
            y profesional para facilitar el proceso de solicitud de los permisos
            de entrada en territorio estadounidense.
          </p>
        </div>
        {/* <div className="footer-section2">
                    <h3>Empresa</h3>
                    <ul>
                        <li>Nosotros</li>
                        <li>Contacto</li>
                        <li>FAQ</li>
                    </ul>
                </div>
                <div className="footer-section2">
                    <h3>Importante</h3>
                    <ul>
                        <li>Términos del Servicio</li>
                        <li>Política de Privacidad</li>
                        <li>Condiciones de Uso</li>
                        <li>Política de Reembolso</li>
                        <li>Política de Cookies</li>
                    </ul>
                </div> */}
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-content">
          <p>© 2021-2024 USA VISA B1B2. Derechos Reservados.</p>
          <a href="#top">Ir al inicio</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
