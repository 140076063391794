// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import V_inicio from "./components/Vistas/Inicio";
import VisaB1B2 from "./components/Vistas/VisaB1B2";
import V_visasDeEmergencia from "./components/Vistas/VisasDeEmergencia";
import V_visasFamiliares from "./components/Vistas/VisasFamiliares";
import V_DS160 from "./components/Vistas/DS160";
import V_FAQ from "./components/Vistas/FAQ";
import V_contacto from "./components/Vistas/Contacto";
import ScrollToTop from "./components/ScrollToTop"; 

import Footer from "./components/Footer";
import Register from "./components/Vistas/Register";
import ExpertProfile from "./components/ExpertProfile";
import "./App.css";
import { UserProvider } from "./context/UserProvider";

const App = () => {
  return (
    <UserProvider>
      <Router>
        <ScrollToTop />
        <div className="app">
          <Routes>
            <Route path="/:usuario" element={<V_inicio />} />
            <Route path="/:usuario/visa-b1b2" element={<VisaB1B2 />} />
            <Route
              path="/:usuario/visas-de-emergencia"
              element={<V_visasDeEmergencia />}
            />
            <Route
              path="/:usuario/visas-familiares"
              element={<V_visasFamiliares />}
            />
            <Route path="/:usuario/ds160" element={<V_DS160 />} />
            <Route path="/:usuario/faq" element={<V_FAQ />} />
            <Route path="/:usuario/contacto" element={<V_contacto />} />
            <Route path="/:usuario/Registro" element={<Register />} />
          </Routes>
          <ExpertProfile />
          <Footer />
        </div>
      </Router>
    </UserProvider>
  );
};

export default App;
