import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import '../css/Inicio.css'; 

const Inicio = () => {
    return (
        <div className="inicio">
            <div className="inicio-content">
                <AnimatedLeftBlock>
                    <div className="inicio-left">
                        <h1>Visa para Estados Unidos</h1>
                        <h2>Solicite su visa de Turismo o Negocio</h2>
                        <ul>
                            <li>-Asistencia paso a paso</li>
                            <li>-Gratis una guía incluida para preparar su entrevista</li>
                            <li>-Formulario simplificado y en español</li>
                            <li>-Miles de visas emitidas y clientes satisfechos</li>
                        </ul>
                    </div>
                </AnimatedLeftBlock>
                <div className="inicio-right">
                    <div className="inicio-right-content">
                        <WhatsAppButton 
                            text="Hola, quiero solicitar el permiso de entrada para Estados Unidos 2024" 
                            buttonText="2024 Permiso de entrada para Estados Unidos" 
                            className="permiso-link"
                        />
                        <h3>Solicite su B1/B2</h3>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar la visa B1/B2" 
                            buttonText={<button className="solicitar-button1">Solicitar</button>}
                            className=""
                        />
                        <WhatsAppButton 
                            text="Hola, quiero renovar mi visa B1/B2" 
                            buttonText={<button className="renovar-button1">Renovar</button>}
                            className=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Inicio;
