import React from 'react';
import '../css/Comparison.css';

const Comparison = () => {
    return (
        <div className="comparison">
            <h2>¿Por qué obtener visas con VISAUSA B1B2?</h2>
            <div className="comparison-content">
                <div className="comparison-card">
                    <div className="recommended">RECOMENDADO</div>
                    <h3>Con VISA USA B1B2</h3>
                    <p>Todas las garantías</p>
                    <div className='Cub'></div>
                    <ul>
                        <li><span className="checkmark">✔</span> Asesoramiento de expertos 24/7</li>
                        <li><span className="checkmark">✔</span> Sin necesidad de registro</li>
                        <li><span className="checkmark">✔</span> Revisión de datos para evitar errores</li>
                        <li><span className="checkmark">✔</span> Modificación de datos gratis</li>
                        <li><span className="checkmark">✔</span> Proceso simplificado</li>
                        <li><span className="checkmark">✔</span> Guía completa</li>
                        <li><span className="checkmark">✔</span> Protección de privacidad</li>
                    </ul>
                </div>
                <div className="comparison-card">
                    <h3>Sin VISA USA B1B2</h3>
                    <p>Sin todas las garantías</p>
                    <div className='Cub'></div>
                    <ul>
                        <li><span className="crossmark">✘</span> Asesoramiento de expertos 24/7</li>
                        <li><span className="crossmark">✘</span> Sin necesidad de registro</li>
                        <li><span className="crossmark">✘</span> Revisión de datos para evitar errores</li>
                        <li><span className="crossmark">✘</span> Modificación de datos gratis</li>
                        <li><span className="crossmark">✘</span> Proceso simplificado</li>
                        <li><span className="crossmark">✘</span> Guía completa</li>
                        <li><span className="crossmark">✘</span> Protección de privacidad</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Comparison;
