import React from "react";
import "../css/ContactForm.css";
import { useUser } from "../context/UserProvider";
const ContactForm = () => {
  const { user } = useUser();
  return (
    <div className="contact-container">
      <div className="contact-info">
        <h2>Contacta</h2>
        <h3>Comunica con nuestros expertos</h3>
        <p>
          Envía un mensaje a nuestro equipo o comunícate en vivo con nuestros
          especialistas en línea usando el botón flotante de la parte inferior a
          la derecha.
        </p>
        <div className="contact-details">
          <div className="contact-detail">
            <img src="/wha.svg" alt="WhatsApp" />
            <div>
              <strong>WhatsApp</strong>
              <p>
                +{user.indicativo} {user.numero}
              </p>
            </div>
          </div>
          <div className="contact-detail">
            <img src="/Mail.svg" alt="Correo" />
            <div>
              <strong>Correo</strong>
              <p>{user.correo}</p>
            </div>
          </div>
          <div className="contact-detail">
            <img src="/Time.svg" alt="Horas de trabajo" />
            <div>
              <strong>Horas de trabajo</strong>
              <p>Lun-Sáb: 09.00 to 17.00 (Domingo Cerrado)</p>
            </div>
          </div>
          <div className="contact-detail">
            <img src="/Call.svg" alt="Horario de soporte" />
            <div>
              <strong>Horario de soporte</strong>
              <p>Abierto todos los días</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
