import React from "react";
import { useUser } from "../context/UserProvider";
import "../css/VisaAdvise.css";

const VisaAdvise = () => {
  const { user } = useUser();

  
  return (
    <div className="visa-advise">
      <div className="visa-advise-content">
        <p>Obtén tu autorización para entrar a EE.UU © VISAS B1/B2</p>
        <a
          href={`https://api.whatsapp.com/send/?phone=${user.indicativo}${user.numero}&text=Hola quiero solicitar la visa&type=phone_number&app_absent=0`}
          target="_blank"
          rel="noreferer noopener"
        >
          <button>SOLICITAR VISA</button>
        </a>
      </div>
    </div>
  );
};

export default VisaAdvise;
