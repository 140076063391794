import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import PackageCard from './animations/PackageCard';
import '../css/Package.css';

const Package = () => {
    const cards = [
        { src: "/Vector.png", alt: "Asesoramiento", text: "Asesoramiento de nuestros expertos" },
        { src: "/Vector-1.png", alt: "Guía completa", text: "Nuestra guía completa" },
        { src: "/Vector-2.png", alt: "Seguimiento paso a paso", text: "Seguimiento paso a paso" },
        { src: "/Vector-3.png", alt: "Soporte profesional 24/7", text: "Soporte profesional 24/7" },
        { src: "/Vector-4.png", alt: "Aplicación simplificada", text: "Aplicación simplificada en línea" },
        { src: "/Vector-5.png", alt: "Pasarelas de pago seguras", text: "Pasarelas de pago seguras" },
    ];

    const rows = [];
    for (let i = 0; i < cards.length; i += 3) {
        rows.push(cards.slice(i, i + 3));
    }

    return (
        <div className="package">
            <WhatsAppButton 
                text="Hola, quiero solicitar la visa B1/B2" 
                buttonText="USA VISA B1/B2" 
                className="visa-link"
            />
            <h3>Un paquete de solicitud que incluye</h3>
            <div className="package-content">
                {rows.map((row, rowIndex) => (
                    <PackageCard key={rowIndex} delay={rowIndex * 0.2}>
                        <div className="package-row">
                            {row.map((item, index) => (
                                <div className="package-card" key={index}>
                                    <img src={item.src} alt={item.alt} className={`icon${index + 1}`} />
                                    <p>{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </PackageCard>
                ))}
            </div>
        </div>
    );
};

export default Package;
