import React, { useEffect } from "react";
import Inicio from "../Inicio";
import LegalAdvertise from "../LegalAdvertise";
import Package from "../Package";
import VisaInfo from "../VisaInfo";
import Process from "../Process";
import Comparison from "../Comparison";
import { useParams } from "react-router-dom";
import { useUser } from "../../context/UserProvider";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_inicio = () => {
  return (
    <div className="app">
      <VisaAdvise />
      <Navbar />
      <Inicio />
      <LegalAdvertise />
      <Package />
      <VisaInfo />
      <Process />
      <Comparison />
    </div>
  );
};

export default V_inicio;
