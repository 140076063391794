import React from "react";
import VisaForm from "../VisaForm";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const Register = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <VisaForm />
    </div>
  );
};

export default Register;
