import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../css/Navbar.css";
import { useUser } from "../context/UserProvider";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const { usuario } = useParams();
  const { user, setUser } = useUser();

  useEffect(() => {
    const fetchUserData = async () => {
      console.log(usuario);
      try {
        const response = await fetch(
          `https://bombazoo.com/api/visa/clave/${usuario}`
        );
        const data = await response.json();
        if (data.ok) {
          const user = data.visa;
          console.log(user);
          setUser({
            ...user,
            usuario: user.clave,
            imagen: user.url_imagen,
          });
        } else {
          setUser({
            nombre: "Carlos Fernandez",
            descripcion: `Mi misión es ser reconocido como el mejor asesor de visado de turista para Estados Unidos. Mi trabajo es convertir tus sueños de viaje en realidad, guiándote paso a paso en el complejo proceso de obtención de tu visa.<br/>
Con años de experiencia en la industria y un profundo conocimiento de las normativas y requisitos del Servicio de Ciudadanía e Inmigración de los Estados Unidos (USCIS), he desarrollado un enfoque meticuloso y personalizado que maximiza tus posibilidades de éxito. Mi dedicación y atención al detalle me han permitido ayudar a miles de personas a obtener su visa de turista, convirtiéndome en el asesor más confiable y eficaz del mercado.<br/>
En visaconéxito.com, te ofrezco un servicio integral que abarca desde la preparación de la documentación hasta la asesoría para tu entrevista consular. Mi compromiso es brindarte un acompañamiento cercano y profesional, asegurando que cada paso del proceso se realice con precisión y sin contratiempos.<br/>
Confía en el mejor asesor de visado de turista para Estados Unidos. Confíame tu asesoramiento y Juntos, haremos que tu viaje sea una experiencia inolvidable.`,
            numero: "3057781450",
            indicativo: "1",
            correo: "info@visasconexito.com",
            imagen:
              "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
          });
        }
      } catch (error) {
        setUser({
          nombre: "Carlos Fernandez",
          descripcion: `Mi misión es ser reconocido como el mejor asesor de visado de turista para Estados Unidos. Mi trabajo es convertir tus sueños de viaje en realidad, guiándote paso a paso en el complejo proceso de obtención de tu visa.<br/>
Con años de experiencia en la industria y un profundo conocimiento de las normativas y requisitos del Servicio de Ciudadanía e Inmigración de los Estados Unidos (USCIS), he desarrollado un enfoque meticuloso y personalizado que maximiza tus posibilidades de éxito. Mi dedicación y atención al detalle me han permitido ayudar a miles de personas a obtener su visa de turista, convirtiéndome en el asesor más confiable y eficaz del mercado.<br/>
En visaconéxito.com, te ofrezco un servicio integral que abarca desde la preparación de la documentación hasta la asesoría para tu entrevista consular. Mi compromiso es brindarte un acompañamiento cercano y profesional, asegurando que cada paso del proceso se realice con precisión y sin contratiempos.<br/>
Confía en el mejor asesor de visado de turista para Estados Unidos. Confíame tu asesoramiento y Juntos, haremos que tu viaje sea una experiencia inolvidable.`,
          numero: "3057781450",
          indicativo: "1",
          correo: "info@visasconexito.com",
          imagen:
            "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
        });
        console.error("Error fetching user data:", error);
      }
    };

    if (!user || user.usuario !== usuario) {
      fetchUserData();
    }
  }, [usuario]);
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest(".navbar")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <nav className="navbar">
      <div className="navbar-content">
        <div className="navbar-logo">
          <Link to={`/${usuario}`}>
            <img src="/Logo.png" alt="Logo" />
          </Link>
        </div>
        <div className="hamburger-menu" onClick={toggleMenu}>
          <img src="/icons8-menu.svg" alt="Menu" />
        </div>
        <ul className={`navbar-links ${isOpen ? "open" : ""}`}>
          <li>
            <Link to={`/${usuario}/visa-b1b2`} onClick={closeMenu}>
              Visa B1 B2
            </Link>
          </li>
          <li>
            <Link to={`/${usuario}/visas-de-emergencia`} onClick={closeMenu}>
              Visas de Emergencia
            </Link>
          </li>
          <li>
            <Link to={`/${usuario}/visas-familiares`} onClick={closeMenu}>
              Visas Familiares
            </Link>
          </li>
          <li>
            <Link to={`/${usuario}/ds160`} onClick={closeMenu}>
              DS-160
            </Link>
          </li>
          <li>
            <Link to={`/${usuario}/faq`} onClick={closeMenu}>
              FAQ
            </Link>
          </li>
          <li>
            <Link to={`/${usuario}/contacto`} onClick={closeMenu}>
              Contacto
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
