import React from "react";
import WhatsAppButton from "./WhatsAppButton";
import "../css/ConoceMas.css";

const ConoceMas = () => {
  return (
    <div className="conoce-mas2">
      <div className="content content3">
        <div className="left">
          <img src="/Ds-160.png" alt="Familia" className="main-image" />
          <img src="/Ds-160_1.png" alt="Familia pequeña" className="small-image" />
        </div>
        <div className="right">
          <a href="#conoce-mas" className="highlight-text">
            Conoce Más
          </a>
          <h1>
            Conoce más ¡Obtenga autorizaciones para toda su familia con un gran
            descuento!
          </h1>
          <p>
            Ofrecemos un precio especial para aquellas personas que tramitan más
            de una autorización con nosotros y todos los miembros de la familia
            podrán asistir a la cita en la embajada el mismo día.
          </p>
          <p>
            Una vez se ha realizado el pago de la tasa de la autorización de
            cada miembro de la familia, procederemos a concertar la cita en la
            embajada o consulado más cercano. Todos los miembros de la familia
            podrán asistir a su cita el mismo día ahorrando tiempo y dinero.
          </p>
          <div className="buttons2">
            <WhatsAppButton 
              text="Hola, quiero solicitar la autorización de entrada a EE.UU para mi familia" 
              buttonText={<button className="solicitar-button10">Solicitar</button>}
              className=""
            />
            <WhatsAppButton 
              text="Hola, quiero renovar la autorización de entrada a EE.UU para mi familia" 
              buttonText={<button className="renovar-button10">Renovar</button>}
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConoceMas;
