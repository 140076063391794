import React from "react";
import AutorizacionUrgente from "../AutorizacionUrgente";
import EmergenciaAutorizacion from "../EmergenciaAutorizacion";
import Ademas from "../Ademas";
import Process from "../Process";
import Process2 from '../Process2';
import AsistenciaPasoAPaso from "../AsistenciaPasoAPaso";
import Comparison from "../Comparison";
import ProfesionalidadExperiencia from "../ProfesionalidadExperiencia";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_visasDeEmergencia = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <AutorizacionUrgente />
      <EmergenciaAutorizacion />
      <Ademas />
      <Process />
      <AsistenciaPasoAPaso />
      <Process2 />
      <Comparison />
      <ProfesionalidadExperiencia />
    </div>
  );
};

export default V_visasDeEmergencia;
