import React from "react";
import AnimatedCard from "./animations/AnimatedCard";
import "../css/SistemaOptimizado.css";

const SistemaOptimizado = () => {
  const cards = [
    {
      src: "/Icon.svg",
      alt: "Icono Servicio",
      text: "En USA Visa B1B2 ofrecemos un servicio único para familias que hace que el proceso de solicitud de autorización sea más sencillo y rápido."
    },
    {
      src: "/Vector.svg",
      alt: "Icono Optimizado",
      text: "A través de nuestro sistema optimizado, usted puede tramitar más de una autorización a la vez. Puede añadir cuantas personas sean necesarias."
    }
  ];

  return (
    <div className="sistema-optimizado">
      <h2>Sistema optimizado</h2>
      <div className="cards">
        {cards.map((card, index) => (
          <AnimatedCard key={index} delay={index * 0.1}>
            <div className="card">
              <img src={card.src} alt={card.alt} className="card-icon" />
              <p>{card.text}</p>
            </div>
          </AnimatedCard>
        ))}
      </div>
    </div>
  );
};

export default SistemaOptimizado;
