import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import AnimatedButtonLong from './animations/AnimatedButtonLong';
import '../css/autorizacion.css'; 

const Autorizacion = () => {
    return (
        <div className="autorizacion">
            <div className="autorizacion-content">
                <AnimatedLeftBlock>
                    <div className="autorizacion-left">
                        <h1>Autorización de entrada a EE.UU</h1>
                        <h2>Solicite su autorización</h2>
                        <ul>
                            <li>-Asistencia paso a paso</li>
                            <li>-Gratis una guía incluida para preparar su entrevista</li>
                            <li>-Formulario simplificado y en español</li>
                            <li>-Miles de visas emitidas y clientes satisfechos</li>
                        </ul>
                    </div>
                </AnimatedLeftBlock>
                <div className="buttons20">
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar la autorización de entrada a EE.UU" 
                            buttonText={<button className="solicitar-button">Solicitar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero renovar mi autorización de entrada a EE.UU" 
                            buttonText={<button className="renovar-button">Renovar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                </div>
            </div>
        </div>
    );
}

export default Autorizacion;
