import React from "react";
import Autorizacion from "../autorizacion";
import AboutAutorizacion from "../AboutAutorizacion";
import RequisitosAutorizacion from "../RequisitosAutorizacion";
import Process2 from '../Process2';
import Process from "../Process";
import CitaEmbajada from "../CitaEmbajada";
import Comparison from "../Comparison";
import ProfesionalidadExperiencia from "../ProfesionalidadExperiencia";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const VisaB1B2 = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <Autorizacion />
      <AboutAutorizacion />
      <RequisitosAutorizacion />
      <Process />
      <CitaEmbajada />
      <Process2 />
      <Comparison />
      <ProfesionalidadExperiencia />
    </div>
  );
};

export default VisaB1B2;
