import React from "react";
import FAQ from "../FAQ";
import FAQQuestions from "../FAQQuestions";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_FAQ = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <FAQ />
      <FAQQuestions />
    </div>
  );
};

export default V_FAQ;
