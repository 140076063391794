import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedImage from './animations/AnimatedImage';
import AnimatedButton from './animations/AnimatedButton';
import '../css/AsistenciaPasoAPaso.css';

const AsistenciaPasoAPaso = () => {
    return (
        <div className="asistencia">
            <div className="asistencia-content">
                <div className="asistencia-left">
                    <AnimatedImage delay={0.2}>
                        <img src="./Asistencia.png" alt="Asistencia personalizada" />
                    </AnimatedImage>
                </div>
                <div className="asistencia-right">
                    <h2>Asistencia personalizada paso a paso</h2>
                    <p>
                        Si el motivo de su viaje y su situación tiene uno de los requisitos, podrá acudir a una cita urgente para una autorización de emergencia. Una vez recibida la documentación, 
                        <strong> un equipo de profesionales expertos se encargará del proceso de tramitación de la autorización a Estados Unidos.</strong> Será el Gobierno de los Estados Unidos <strong>quien apruebe</strong> la cita de emergencia.
                    </p>
                    <p>
                        <strong>En caso de no aprobarse la cita urgente, sin algún coste añadido tendrá una cita regular ya confirmada.</strong>
                    </p>
                    <AnimatedButton delay={0.4}>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar una visa de emergencia" 
                            buttonText={<button className="btn-emergencia">Solicitar visa de emergencia</button>}
                            className=""
                        />
                    </AnimatedButton>
                </div>
            </div>
        </div>
    );
}

export default AsistenciaPasoAPaso;
