import React from 'react';
import '../css/Ademas.css';

const Ademas = () => {
    return (
        <div className="ademas">
            <h2>ADEMÁS</h2>
            <p>Si cumple con uno de los requisitos mencionados, podemos proceder a solicitar una autorización urgente.</p>
        </div>
    );
}

export default Ademas;
