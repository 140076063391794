import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import AnimatedButtonLong from './animations/AnimatedButtonLong';
import '../css/FormularioDS160.css';

const FormularioDS160 = () => {
    return (
        
        <div className="formulario-ds160">
            <div className='formulario_max'>
                <AnimatedLeftBlock>
                    <div className="formulario-content">
                        <h2>FORMULARIO DS-160</h2>
                        <h1>Tramite su autorización de entrada a EEUU</h1>
                        <ul>
                            <li>Formulario DS160 simplificado y en español (actualizado abril de 2024)</li>
                            <li>Guía gratuita para su cita en la embajada</li>
                            <li>Asistencia al completar el formulario con expertos las 24/7 los 365 días del año</li>
                        </ul>
                    </div>
                </AnimatedLeftBlock>
                <div className="buttons45">
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar la autorización de entrada a EE.UU" 
                            buttonText={<button className="solicitar-button5">Solicitar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero renovar la autorización de entrada a EE.UU" 
                            buttonText={<button className="renovar-button5">Renovar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                </div>
            </div>
        </div>
    );
}

export default FormularioDS160;
