import React from "react";
import WhatsAppButton from "./WhatsAppButton";
import AnimatedButton from "./animations/AnimatedButton";
import "../css/VisaInfo.css";

const VisaInfo = () => {
  return (
    <div className="visa-info">
      <div className="visa-info-content">
        <div className="visa-info-image-container">
          <div className="image-label">VISAS de negocio y turismo</div>
          <img
            src="/Banner.png"
            alt="Visa Information"
            className="visa-info-image"
          />
        </div>
        <div className="visa-info-text">
          <h2>La forma más fácil de obtener visas</h2>
          <p className="description">
            Ponemos a su disposición nuestro equipo de expertos en la
            tramitación de autorizaciones de entrada a territorio estadounidense
            que le ayudarán en todo el proceso. Podrá contar con ellos para
            dudas sobre su aplicación y le guiarán para evitar errores formales
            durante todo el proceso.
          </p>
          <p className="additional-info">
            Incluida en nuestro paquete de servicios, encontrará nuestra guía
            completa guía, una herramienta infalible para preparar su entrevista
            con el consulado o la embajada americana, así como toda la
            información que necesite para entender de forma completa y sencilla
            todo el proceso de solicitud.
          </p>
          <AnimatedButton delay={0.2}>
            <div className="button-row">
              <WhatsAppButton 
                text="Hola, quiero solicitar la visa B1/B2" 
                buttonText={<button className="solicitar-button">Solicitar</button>}
                className=""
              />
              <WhatsAppButton 
                text="Hola, quiero renovar mi visa B1/B2" 
                buttonText={<button className="renovar-button">Renovar</button>}
                className=""
              />
            </div>
          </AnimatedButton>
        </div>
      </div>
    </div>
  );
};

export default VisaInfo;
