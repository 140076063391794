import React from "react";
import FormularioDS160 from "../FormularioDS160";
import FormularioDetalleDS160 from "../FormularioDetalleDS160";
import GuiaEntrevista from "../GuiaEntrevista";
import Process2 from "../Process2";
import Comparison from "../Comparison";
import ProfesionalidadExperiencia from "../ProfesionalidadExperiencia";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_DS160 = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <FormularioDS160 />
      <FormularioDetalleDS160 />
      <GuiaEntrevista />
      <Process2 />
      <Comparison />
      <ProfesionalidadExperiencia />
    </div>
  );
};

export default V_DS160;
