import React from "react";
import WhatsAppButton from "./WhatsAppButton";
import AnimatedCard from "./animations/AnimatedCard";
import AnimatedButton from "./animations/AnimatedButton";
import "../css/Process.css";

const Process2 = () => {
  const processSteps = [
    {
      icon: "/icon1.png",
      alt: "Llena tu solicitud",
      title: "Llena tu solicitud en línea",
      description: "Completa nuestro formulario para procesar tu solicitud",
      number: "01"
    },
    {
      icon: "/icon2.png",
      alt: "Entrevista en el consulado",
      title: "Entrevista en el consulado",
      description: "Acude a tu entrevista con tu formulario DS-160",
      number: "02"
    },
    {
      icon: "/icon3.png",
      alt: "Viaja a Estados Unidos",
      title: "Viaja a Estados Unidos",
      description: "Arriba a los Estados Unidos con tu visa B1/B2",
      number: "03",
      link: "READ MORE"
    }
  ];

  return (
    <div className="process">
      <WhatsAppButton 
        text="Hola, quiero solicitar la visa B1/B2" 
        buttonText="con Visa USA B1/B2" 
        className="process-link"
      />
      <h2>El proceso es simple</h2>
      <div className="process-content">
        {processSteps.map((step, index) => (
          <AnimatedCard key={index} delay={index * 0.2}>
            <div className="process-card">
              <img
                src={step.icon}
                alt={step.alt}
                className="process-icon"
              />
              <div className="process-card-text">
                <h3>{step.title}</h3>
                <p>{step.description}</p>
                {step.link && <a href="dfgdfv" className="read-more">{step.link}</a>}
              </div>
              <div className="process-number">{step.number}</div>
            </div>
          </AnimatedCard>
        ))}
      </div>
      <AnimatedButton delay={0.2}>
        <div className="buttons21">
          <WhatsAppButton 
            text="Hola, quiero solicitar la visa B1/B2" 
            buttonText={<button className="solicitar-button21">Solicitar</button>}
            className=""
          />
          <WhatsAppButton 
            text="Hola, quiero renovar mi visa B1/B2" 
            buttonText={<button className="renovar-button21">Renovar</button>}
            className=""
          />
        </div>
      </AnimatedButton>
    </div>
  );
};

export default Process2;
