import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import AnimatedButtonLong from './animations/AnimatedButtonLong';
import '../css/AutorizacionFamilias.css';

const AutorizacionFamilias = () => {
    return (
        <div className="autorizacion-familias">
            <div className="content clas_con">
                <AnimatedLeftBlock>
                    <div className='Content_auto'>
                        <h2>Autorización de entrada a EE. UU. para Familias</h2>
                        <h1>Solicite su autorización</h1>
                        <ul>
                            <li>- Asistencia paso a paso</li>
                            <li>- Gratis una guía incluida para preparar su entrevista</li>
                            <li>- Formulario simplificado y en español</li>
                            <li>- Miles de visas emitidas y clientes satisfechos</li>
                        </ul>
                    </div>
                </AnimatedLeftBlock>
                <div className="buttons2">
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar la autorización de entrada a EE.UU para familias" 
                            buttonText={<button className="solicitar-button11">Solicitar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero renovar la autorización de entrada a EE.UU para familias" 
                            buttonText={<button className="renovar-button11">Renovar</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                </div>
            </div>
        </div>
    );
}

export default AutorizacionFamilias;
