import React from 'react';
import '../css/RequisitosAutorizacion.css';
import AnimatedCard from './animations/AnimatedCard';

const RequisitosAutorizacion = () => {
    return (
        <div className="requisitos-autorizacion">
            <a href='wjoreufnw'>QUÉ NECESITA</a>
            <p>Para poder tramitar la autorización B1 o la autorización B2 usted necesita contar con la siguiente documentación:</p>
            <div className="requisitos-cards">
                <AnimatedCard>
                    <div className="requisito-card">
                        <img src="/icon6.png" alt="Pasaporte actualizado" />
                        <p>Pasaporte actualizado</p>
                    </div>
                </AnimatedCard>
                <AnimatedCard>
                    <div className="requisito-card">
                        <img src="/Vector-4.png" alt="Hoja de confirmación DS-160" />
                        <p>La hoja de confirmación de envío de la solicitud DS-160</p>
                    </div>
                </AnimatedCard>
                <AnimatedCard>
                    <div className="requisito-card">
                        <img src="/icon7.png" alt="Recibo de pago en el banco" />
                        <p>Recibo del pago de la autorización en el banco</p>
                    </div>
                </AnimatedCard>
            </div>
        </div>
    );
}

export default RequisitosAutorizacion;
