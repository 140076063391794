import React from "react";
import WhatsAppButton from "./WhatsAppButton";
import "../css/AboutAutorizacion.css";

const AboutAutorizacion = () => {
  return (
    <div className="about-autorizacion">
      <div className="about-content">
        <div className="about-images">
          <img src="/Girl2.png" alt="Autorizacion" className="main-image" />
          <img src="/Girl.png" alt="Autorizacion" className="secondary-image" />
        </div>
        <div className="about-text">
          <h3>Conoce Más</h3>
          <h1>Sobre esta autorización</h1>
          <p>
            La autorización de turismo y la autorización de negocios son
            consideradas autorizaciones de no inmigrante y sirven para visitar
            los Estados Unidos por temporadas cortas. La autorización de turismo
            es conocida como la autorización B2, y la autorización de negocios
            es conocida como la autorización B1. El proceso para obtener la
            autorización B1 y la autorización B2 es exactamente el mismo, la
            única variante es el propósito de viaje.
          </p>
          <p>
            Una autorización B2 permite que el viajero pueda participar en
            actividades turísticas como: visitar parques de atracciones, ir de
            compras, conocer sitios históricos, visitar amigos o parientes e
            incluso obtener tratamiento médico de corta duración. Por su parte,
            la autorización B1 permite que su portador participe en actividades
            de negocio: la negociación de contratos, asistir a conferencias,
            entrenamientos o formaciones cortas, consultas con proveedores, etc.
          </p>
          <div className="buttons">
            <WhatsAppButton 
              text="Hola, quiero solicitar la autorización de entrada a EE.UU" 
              buttonText={<button className="solicitar-button2">Solicitar</button>}
              className=""
            />
            <WhatsAppButton 
              text="Hola, quiero renovar mi autorización de entrada a EE.UU" 
              buttonText={<button className="renovar-button2">Renovar</button>}
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutAutorizacion;
