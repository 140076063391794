import React from 'react';
import '../css/FAQ.css';

const FAQ = () => {
    return (
        <div className="faq">
            <div className="faq-overlay"></div>
            <div className="faq-content">
                <h1>FAQ</h1>
            </div>
        </div>
    );
}

export default FAQ;
