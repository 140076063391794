import React from 'react';
import AnimatedCard from './animations/AnimatedCard';
import CountUp from './animations/CountUp';
import '../css/ProfesionalidadExperiencia.css';

const ProfesionalidadExperiencia = () => {
    const cards = [
        { src: "/p_icon3.svg", alt: "Solicitudes", value: 9000, text: "Solicitudes" },
        { src: "/p_icon4.svg", alt: "Guías descargadas", value: 8000, text: "Guías descargadas" },
        { src: "/p_icon2.svg", alt: "Entrevistas", value: 7000, text: "Entrevistas" },
        { src: "/p_icon1.svg", alt: "Viajes a EE.UU", value: 4000, text: "Viajes a EE.UU", isSvg: true }
    ];

    return (
        <div className="profesionalidad-experiencia">
            <h2>Profesionalidad Y Experiencia</h2>
            <div className="tarjetas">
                {cards.map((card, index) => (
                    <AnimatedCard key={index} delay={index * 0.2}>
                        <div className="tarjeta">
                            <div className="icono-valor">
                                <img src={card.src} alt={card.alt} className={card.isSvg ? 'ima_svg' : ''} />
                                <CountUp endValue={card.value} duration={2000} />
                            </div>
                            <p className={card.isSvg ? 'svg_sub' : ''}>{card.text}</p>
                        </div>
                    </AnimatedCard>
                ))}
            </div>
        </div>
    );
}

export default ProfesionalidadExperiencia;
