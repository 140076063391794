import React from 'react';
import '../css/LegalAdvertise.css'; 

const LegalAdvertise = () => {
    return (
        <div className="legal-advertise">
            <div className="legal-advertise-content">
                <p>
                    Aviso legal: US Visa B1B2 es una empresa especializada en soporte a trámites de viaje. La tarifa de nuestros servicios incluye la asistencia experta y profesional para facilitar el proceso de solicitud de los permisos de entrada en territorio estadounidense. Las tasas del gobierno no están incluidas en nuestro precio final. Esta página y sus servicios no están afiliadas al gobierno, y la decisión final de otorgar una autorización es del gobierno americano. Usted puede realizar este trámite directamente con el gobierno americano sin los gastos del soporte profesional que ofrecemos.
                </p>
            </div>
        </div>
    );
}

export default LegalAdvertise;
