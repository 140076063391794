import React from 'react';
import '../css/VisaForm.css';

const VisaForm = () => {
    return (
        <div className="visa-form-container">
            <h1>Solicitud de Visa B1/B2</h1>
            <p className="form-subtitle">Llena el formulario ahora y obtén $100 de descuento en tu trámite</p>
            <div className="form-section">
                <div className='raya'></div>
                <div className="section-header">
                    <div className="section-step">1/4</div>
                    <h2>Datos generales</h2>
                </div>
                <form>
                    <div className="form-group">
                        <select name="country" required>
                            <option value="" disabled selected>País desde donde solicita *</option>
                            <option value="country1">País 1</option>
                            <option value="country2">País 2</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <input type="text" name="name" placeholder="Nombre *" required />
                    </div>
                    <p>Introduzca su nombre tal y como aparece en su DNI/CIN</p>

                    <div className="form-group">
                        <input type="text" name="surname" placeholder="Apellidos *" required />
                    </div>
                    <p>Introduzca sus apellidos tal y como aparece en su DNI/CIN</p>

                    <div className="Checbox">
                        <input type="checkbox" name="other_name" id="other_name" />
                        <label htmlFor="other_name">¿Alguna vez ha utilizado otro nombre?</label>
                    </div>

                    <div className="form-group">
                        <select name="gender" required>
                            <option value="" disabled selected>Sexo *</option>
                            <option value="male">Masculino</option>
                            <option value="female">Femenino</option>
                            <option value="other">Otro</option>
                        </select>
                    </div>

                    <div className="form-group">
                        <input type="email" name="email" placeholder="Correo electrónico *" required />
                    </div>
                    <p>Asegúrese de usar un correo correcto.</p>

                    <div className="Checbox">
                        <input type="checkbox" name="other_email" id="other_email" />
                        <label htmlFor="other_email">¿Ha utilizado otro correo electrónico en los últimos 5 años?</label>
                    </div>

                    <button type="submit" className="submit-button">Siguiente</button>
                    <div className='Raya2'></div>
                </form>
            </div>
        </div>
    );
}

export default VisaForm;
