import React from "react";
import AutorizacionFamilias from "../AutorizacionFamilias";
import ConoceMas from "../ConoceMas";
import SistemaOptimizado from "../SistemaOptimizado";
import Process2 from "../Process2";
import Comparison from "../Comparison";
import ProfesionalidadExperiencia from "../ProfesionalidadExperiencia";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_visasFamiliares = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <AutorizacionFamilias />
      <ConoceMas />
      <SistemaOptimizado />
      <Process2 />
      <Comparison />
      <ProfesionalidadExperiencia />
    </div>
  );
};

export default V_visasFamiliares;
