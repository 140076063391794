import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import AnimatedLeftBlock from './animations/AnimatedLeftBlock';
import AnimatedButtonLong from './animations/AnimatedButtonLong';
import '../css/AutorizacionUrgente.css';

const AutorizacionUrgente = () => {
    return (
        <div className="autorizacion-urgente">
            <div className="autorizacion-content-urgente">
                <AnimatedLeftBlock>
                    <div className='autorizacion_content'>
                        <h2>Solicite una autorización urgente con cita de emergencia</h2>
                        <h1>Obtenga su autorización en un tiempo más corto si cumple algunos de estos requisitos:</h1>
                        <ul className="requisitos-urgente">
                            <li>Necesita recibir asistencia médica urgentemente en los Estados Unidos.</li>
                            <li>Debe acompañar a un familiar directo que requiere tratamiento médico urgente.</li>
                            <li>Un familiar suyo directo en los EE.UU requiere urgentemente su presencia por una condición médica seria.</li>
                            <li>Debe asistir al funeral de un miembro de familia directo.</li>
                            <li>Debe presentarse en una corte de los Estados Unidos.</li>
                        </ul>
                        <p className="nota-urgente">
                            Si cumple con uno de los requisitos mencionados, podemos proceder a solicitar una <strong>autorización urgente</strong>. La decisión final
                            sobre la aprobación de una cita de emergencia siempre depende del Gobierno de los Estados Unidos.
                        </p>
                    </div>
                </AnimatedLeftBlock>
                <div className="botones-urgente">
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar una visa de emergencia" 
                            buttonText={<button className="solicitar-emergencia-urgente">Solicitar visa de emergencia</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                    <AnimatedButtonLong>
                        <WhatsAppButton 
                            text="Hola, quiero solicitar una visa regular" 
                            buttonText={<button className="visa-regular-urgente">Visa regular</button>}
                            className=""
                        />
                    </AnimatedButtonLong>
                </div>
                <AnimatedLeftBlock>
                <p className="nota-final-urgente">
                    Si no cumple con ninguno de los requisitos mencionados, podemos proceder a solicitar una autorización regular. <br />
                    *El precio desde sólo USD 129 + tarifa del gobierno USD 160.
                </p>
                </AnimatedLeftBlock>
            </div>
        </div>
    );
}

export default AutorizacionUrgente;
