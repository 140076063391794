import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

const CountUp = ({ endValue, duration }) => {
    const [count, setCount] = useState(0);
    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    useEffect(() => {
        if (!inView) return;

        let start = 0;
        const increment = endValue / (duration / 10);

        const timer = setInterval(() => {
            start += increment;
            if (start >= endValue) {
                clearInterval(timer);
                setCount(endValue);
            } else {
                setCount(Math.floor(start));
            }
        }, 10);

        return () => clearInterval(timer);
    }, [inView, endValue, duration]);

    return <span ref={ref}>{count}+</span>;
};

export default CountUp;
