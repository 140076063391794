import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import '../css/FormularioDetalleDS160.css';

const FormularioDetalleDS160 = () => {
    return (
        <div className="formulario-detalle-ds160">
            <div className="formulario-detalle-content">
                <div className="formulario-detalle-image">
                    <img src="/For.png" alt="Formulario DS-160" className="main-image" />
                    <img src="/For_1.png" alt="Ayuda de expertos" className="small-image" />
                </div>
                <div className="formulario-detalle-text">
                    <a href="#conoce-mas" className="conoce-mas">El Formulario Oficial Simplificado Y En Español</a>
                    <h1>DS-160</h1>
                    <p>El formulario DS-160 oficial del gobierno ha sido adaptado para que sea más sencillo de completar. Actualizado con los últimos cambios en abril de 2024, las preguntas oficiales traducidas al español, y con todo el tiempo necesario para rellenarlo ya que su sesión no expirará.</p>
                    <a href="#personalizado" className="personalizado">100% Personalizado</a>
                    <h2>Ayuda de nuestros expertos</h2>
                    <p>Tendrá a su disposición un equipo de profesionales expertos en el formulario DS-160 y en la tramitación de la autorización de turistas y de negocios B1B2 a Estados Unidos. Tramitando su solicitud con nosotros podrá recibir el apoyo de nuestro equipo y solventar todas sus dudas.</p>
                    <WhatsAppButton 
                        text="Hola, quiero realizar una nueva solicitud para la autorización de entrada a EE.UU" 
                        buttonText={<button className="nueva-solicitud-button">NUEVA SOLICITUD</button>}
                        className=""
                    />
                </div>
            </div>
        </div>
    );
}

export default FormularioDetalleDS160;
