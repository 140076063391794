import React from "react";
import Contacto from "../Contacto";
import ContactForm from "../ContactForm";
import VisaAdvise from "../VisaAdvise";
import Navbar from "../Navbar";
const V_contacto = () => {
  return (
    <div>
      <VisaAdvise />
      <Navbar />
      <Contacto />
      <ContactForm />
    </div>
  );
};

export default V_contacto;
