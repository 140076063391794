import React from 'react';
import WhatsAppButton from './WhatsAppButton';
import '../css/EmergenciaAutorizacion.css';

const EmergenciaAutorizacion = () => {
    return (
        <div className="emergencia-autorizacion">
            <div className="emergencia-content">
                <div className="emergencia-left">
                    <img src="./NA.png" alt="Visas de Emergencia" className="image-left" />
                    <span className="emergencia-label">Visas de Emergencia</span>
                </div>
                <div className="emergencia-right">
                    <WhatsAppButton 
                        text="Hola, quiero solicitar una visa de emergencia de forma urgente" 
                        buttonText="Solicite de forma urgente su autorización americana" 
                        className="urgente-link"
                    />
                    <h1>y consiga su autorización en tiempo record</h1>
                    <p>
                        A través de nuestra plataforma, su proceso de solicitud será rápido y sencillo. Ofrecemos un formulario simplificado que incluye todas las preguntas oficiales del gobierno. Usted podrá completarlo con tranquilidad desde su casa u oficina.
                    </p>
                    <div className="botones-emergencia">
                        <WhatsAppButton 
                            text="Hola, quiero solicitar una visa de emergencia" 
                            buttonText={<button className="visa-emergencia">Visa de Emergencia</button>}
                            className=""
                        />
                        <WhatsAppButton 
                            text="Hola, quiero solicitar una visa regular" 
                            buttonText={<button className="visa-regular">Visa Regular</button>}
                            className=""
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmergenciaAutorizacion;
