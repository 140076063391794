import React, { createContext, useState, useContext } from "react";

const UserContext = createContext(null);

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    nombre: "Carlos Fernandez",
    descripcion: `Mi misión es ser reconocido como el mejor asesor de visado de turista para Estados Unidos. Mi trabajo es convertir tus sueños de viaje en realidad, guiándote paso a paso en el complejo proceso de obtención de tu visa.<br/>
Con años de experiencia en la industria y un profundo conocimiento de las normativas y requisitos del Servicio de Ciudadanía e Inmigración de los Estados Unidos (USCIS), he desarrollado un enfoque meticuloso y personalizado que maximiza tus posibilidades de éxito. Mi dedicación y atención al detalle me han permitido ayudar a miles de personas a obtener su visa de turista, convirtiéndome en el asesor más confiable y eficaz del mercado.<br/>
En visaconéxito.com, te ofrezco un servicio integral que abarca desde la preparación de la documentación hasta la asesoría para tu entrevista consular. Mi compromiso es brindarte un acompañamiento cercano y profesional, asegurando que cada paso del proceso se realice con precisión y sin contratiempos.<br/>
Confía en el mejor asesor de visado de turista para Estados Unidos. Confíame tu asesoramiento y Juntos, haremos que tu viaje sea una experiencia inolvidable.`,
    numero: "3057781450",
    indicativo: "1",
    correo: "info@visasconexito.com",
    imagen:
      "https://content.app-sources.com/s/59984382634598608/uploads/Images/Portada_video_CV-4309922.png?format=webp",
  });

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
