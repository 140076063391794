import React, { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const PackageCard = ({ children, delay }) => {
    const { ref, inView, entry } = useInView({
        triggerOnce: false,
        threshold: 0.1,
    });
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        if (inView) {
            setHasAnimated(true);
        } else if (entry && entry.boundingClientRect.top > 0) {
            setHasAnimated(false);
        }
    }, [inView, entry]);

    return (
        <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={hasAnimated ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.5, delay }}
            className="package-row"
        >
            {children}
        </motion.div>
    );
};

export default PackageCard;
